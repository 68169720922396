import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FaSearch, FaComments } from 'react-icons/fa';
import styles from './Chatbot.module.css';
import { GoogleGenerativeAI } from "@google/generative-ai";
import systemInstruction from './Systems_instruction.json'; // Import the JSON file
import { useChat } from '../context/ChatContext';

const prompts = [
  "Curious about my skills? Ask away!",
  "Skip the scrolling, let's chat about my experience",
  "Got a burning question? I'm all ears!",
  "Feeling lucky? Ask me anything about my portfolio",
  "Let's cut to the chase - what do you want to know?",
  "Psst... I know all the secrets of this CV",
  "Why read when you can chat? Hit me with your best question!"
];

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const Chatbot = () => {
  const { isOpen, toggleChat, openChat } = useChat();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [currentPrompt] = useState(() => prompts[Math.floor(Math.random() * prompts.length)]);
  const [displayedPrompt, setDisplayedPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cvData, setCvData] = useState(null);
  const [isChatInitialized, setIsChatInitialized] = useState(false); // Track if chat is initialized

  const streamPrompt = useCallback(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      if (index <= currentPrompt.length) {
        setDisplayedPrompt(currentPrompt.slice(0, index));
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 50); // Adjust the interval (in milliseconds) to control the speed of the streaming

    return () => clearInterval(intervalId);
  }, [currentPrompt]);

  useEffect(() => {
    const cleanup = streamPrompt();
    return cleanup;
  }, [streamPrompt]);

  useEffect(() => {
    // Use the imported systemInstruction directly
    setCvData(systemInstruction.cv);
  }, []);

  useEffect(() => {
    if (!isChatInitialized) {
      setMessages([
        { 
          text: "Hi! I have Sia's information. Feel free to ask me questions about his work experience, skills, or background.", 
          sender: 'bot' 
        }
      ]);
      setIsChatInitialized(true);
    }
  }, []); // Empty dependency array means this runs once on mount

  const handleSend = async (e) => {
    e.preventDefault();
    if (input.trim()) {
      setIsLoading(true);
      setError(null);
      setMessages([...messages, { text: input, sender: 'user' }]);
      
      let retries = 0;
      const attemptRequest = async () => {
        try {
          const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);
          const model = genAI.getGenerativeModel({ model: "gemini-pro" });

          await new Promise(resolve => setTimeout(resolve, 1000));

          const systemPrompt = `${systemInstruction.instruction}\n\nCV Information:\n${JSON.stringify(systemInstruction.cv, null, 2)}`;

          const result = await model.generateContent({
            contents: [
              { role: "user", parts: [{ text: systemPrompt }] },
              { role: "model", parts: [{ text: "Understood, I'll use this information to answer questions about the CV." }] },
              { role: "user", parts: [{ text: input }] }
            ],
            generationConfig: {
              temperature: 0.9,
              topK: 1,
              topP: 1,
              maxOutputTokens: 2048,
            },
            safetySettings: [
              {
                category: "HARM_CATEGORY_HARASSMENT",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
              },
              {
                category: "HARM_CATEGORY_HATE_SPEECH",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
              },
              {
                category: "HARM_CATEGORY_SEXUALLY_EXPLICIT",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
              },
              {
                category: "HARM_CATEGORY_DANGEROUS_CONTENT",
                threshold: "BLOCK_MEDIUM_AND_ABOVE"
              },
            ],
          });

          const botResponse = result.response.text();
          setMessages(prevMessages => [...prevMessages, { text: botResponse, sender: 'bot' }]);
          setIsChatInitialized(true);
        } catch (error) {
          console.error('Error details:', {
            status: error.status,
            message: error.message,
            timestamp: new Date().toISOString()
          });
          
          if (error.message.includes('429')) {
            setError('API quota exceeded. Please try again tomorrow.');
          } else if (error.message.includes('500') || error.message.includes('503')) {
            setError('Service temporarily unavailable. Please try again in a few minutes.');
          } else {
            setError('An unexpected error occurred. Please try again.');
          }
          throw error;
        }
      };

      try {
        await attemptRequest();
      } catch (error) {
        // This catch block will handle any errors that weren't resolved by retries
        console.error('All retries failed:', error);
      } finally {
        setIsLoading(false);
        setInput('');
        openChat();
      }
    }
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleToggleChat = () => {
    console.log('Toggle clicked, initialized:', isChatInitialized);
    toggleChat();
  };

  useEffect(() => {
    console.log('Current messages:', messages);
  }, [messages]);

  return (
    <div className={styles.chatbotContainer}>
      <form onSubmit={handleSend} className={styles.chatInput}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={displayedPrompt}
          className={styles.inputField}
        />
        <button type="submit" className={styles.sendButton}>
          <FaComments />
        </button>
      </form>

      <button onClick={handleToggleChat} className={styles.toggleButton}>
        <FaComments />
      </button>

      {isOpen && (
        <div className={styles.chatWindow}>
          <div className={styles.chatMessages}>
            {messages.map((message, index) => (
              <div 
                key={index} 
                className={`${styles.message} ${styles[message.sender]}`}
              >
                {message.text}
              </div>
            ))}
            {isLoading && <div className={styles.loading}>Loading...</div>}
            {error && <div className={styles.error}>{error}</div>}
            <div ref={messagesEndRef} /> {/* Add this div to act as a scroll target */}
          </div>
          {/* Chat input form */}
          <form onSubmit={handleSend} className={styles.chatInput}>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your message..."
              className={styles.inputField}
            />
            <button type="submit" className={styles.sendButton}>
              <FaSearch />
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
